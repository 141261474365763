import 'core-js/stable';
import objectFitImages from 'object-fit-images';

console.log('Polyfills ready');
window.usingPolyfill = true;

// Object-Fit Polyfill
window.objectFitImages = objectFitImages;

document.addEventListener('DOMContentLoaded', function() {
    objectFitImages('img');
});

window.addEventListener('resize', function() {
    objectFitImages('img');
});